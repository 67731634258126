<template>
  <div id="contact-us" class="position-relative" style="background-color:#f5f9fc;">
    <div class="container position-relative z-index-2">

      <!-- ========== Terms & Copyright ========== -->
      <div class="space-2">
        <!-- <div class="mb-4">
          <router-link :to="{ path: '/' }">
            <img class="brand" src="@/assets/svg/logos/logo-white.svg">
          </router-link>
        </div> -->
        <div class="row align-items-md-center mb-7">
          <!-- Terms -->
          <div class="col-md-6 mb-4 mb-md-0 z-index-999">
            <ul class="nav nav-sm nav-x-sm align-items-center">
              <li class="nav-item">
                <a class="nav-link" href="https://www.benovelty.com/terms-of-use" target="_blank">Terms of Use</a>
                <!-- <router-link class="nav-link text-primary" :to="{ path: '/terms-of-use' }">Terms of Use</router-link> -->
              </li>
              <li class="nav-item opacity mx-3">/</li>
              <li class="nav-item">
                <a class="nav-link" href="https://www.benovelty.com/privacy-policy" target="_blank">Privacy Policy</a>
                <!-- <router-link class="nav-link text-primary" :to="{ path: '/privacy-policy' }">Privacy Policy</router-link> -->
              </li>
            </ul>
          </div>
          <!-- End Terms -->

          <!-- Social Networks -->
          <div class="col-md-6 text-md-right z-index-999">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-primary" href="mailto: info@beNovelty.com">
                  <i class="fas fa-envelope" />
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-primary" href="https://www.facebook.com/beNovelty" target="_blank">
                  <i class="fab fa-facebook-f" />
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-primary" href="https://www.linkedin.com/company/api-connect-hong-kong" target="_blank">
                  <i class="fab fa-linkedin" />
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-primary" href="https://www.youtube.com/@openapihub" target="_blank">
                  <i class="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
          <!-- End Social Networks -->
        </div>

        <!-- Copyright -->
        <div class="w-md-75 text-lg-center mx-lg-auto z-index-999">
          <p class="small">© {{ new Date().getFullYear() }} All Rights Reserved by <a href="https://www.benovelty.com/" target="_blank">beNovelty</a>.</p>
        </div>
        <!-- End Copyright -->
      </div>
      <!-- ========== End Terms & Copyright ========== -->
    </div>
  </div>
</template>

<script>
// import 'bootstrap'
export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
